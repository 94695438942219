import  React, {useState} from "react";
import {Helmet} from "react-helmet";
import PageLayout from "../PageLayout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEnvelope, faMapMarkerAlt,
    faPaperPlane, faPhone,

} from "@fortawesome/free-solid-svg-icons";
import SectionLayout from "../SectionLayout";
import axios from "axios";


const Contact = () => {
    const [recieved, setRecieved] = useState(false);
    return (
        <PageLayout
            noButton
            title={"Contact Us"}
            navSize={"sm:h-80 md:h-80 h-72"}
            titleStyles={"xl:mt-12 lg:mt-16 md:mt-16 sm:mt-20 mt-16"}
        >
            <Helmet>
                <title>
                    EPG Solutions | Contact Us
                </title>
                <meta
                    name="description"
                    content="Contact the E-P-G Solutions team. We have the expertise and experience to meet your remote power generation needs across the Northern Territory."
                />
            </Helmet>
            <div className={"bg-epg-yellow h-5"}/>
            <SectionLayout bgColor={"bg-epg-white"}>
                <div className={"grid md:grid-cols-3 md:gap-10 lg:gap-16 lg:py-6"}>
                    <div className={"md:col-span-2"}>
                        {recieved ? <p className={"font-exo2 text-epg-gray text-md lg:text-lg"}>Your message has been recieved!</p>: null}
                        <p
                            className={
                                "pt-10 leading-7 font-exo2 text-epg-gray  lg:leading-8 lg:text-lg mb-6"
                            }
                        >
                            Get in touch with the EPG Solutions Team. We have the expertise
                            and experience to meet your remote power generation needs across
                            the Northern Territory.
                        </p>
                        <Form toggleRecieved={() => {
                            setRecieved(true);
                        }}/>
                    </div>
                    <div className={"my-10 md:mt-10 grid md:grid-rows-3 "}>
                        <div className={""}>
                            <h3 className={" font-semibold font-exo2 text-xl text-epg-gray mb-6  lg:text-2xl"}>Contact Details</h3>
                            <div className={"mb-4 flex"}>
                                <FontAwesomeIcon
                                    className={
                                        "text-epg-gray hover:text-epg-yellow lg:text-3xl text-2xl mr-3 "
                                    }
                                    icon={faPhone}
                                />
                                <a
                                    className={"font-exo2 text-epg-gray text-md   lg:text-lg"}
                                    href="tel:1800374765"
                                >
                                    1800 374 765 (1800 EPGSOL)
                                </a>
                            </div>
                            <div className={"mb-4 flex"}>
                                <FontAwesomeIcon
                                    className={
                                        "text-epg-gray hover:text-epg-yellow text-2xl lg:text-3xl mr-3"
                                    }
                                    icon={faEnvelope}
                                />
                                <a
                                    className={"font-exo2 text-epg-gray text-md lg:text-lg"}
                                >
                                    epg@<span className={"hidden"}>hidden-message</span>epgsolutions.com.au
                                </a>
                            </div>
                            <div className={"mb-4 flex align-center"}>
                                <FontAwesomeIcon
                                    className={
                                        "text-exo2 text-epg-gray hover:text-epg-yellow lg:text-3xl text-2xl ml-1 mr-3.5"
                                    }
                                    icon={faMapMarkerAlt}
                                />
                                <p className={"font-exo2 text-epg-gray text-md lg:text-lg"}>
                                    6 Baban Place, Pinelands, NT 0829
                                </p>
                            </div>
                        </div>
                        <div className={"row-span-2"}>
                            <iframe
                                className={"border-0 pt-5 bg-epg-white w-full h-96"}
                                loading="lazy"
                                allowFullScreen
                                src="https://maps.google.com/maps?q=6+Baban+Place,+Pinelands,+NT+0829&t=m&z=15&output=embed&iwloc=near"
                                title="EPG Solutions"
                            />
                        </div>
                    </div>

                </div>


            </SectionLayout>
        </PageLayout>
    );
};

const Form = ({toggleRecieved}) => {
    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
    });
    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg }
        });
        if (ok) {
            form.reset();
        }
    };
    const handleOnSubmit = e => {
        e.preventDefault();
        const form = e.target;
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: "https://getform.io/f/156b4512-4a35-4649-9d26-dc84c614bffd",
            data: new FormData(form)
        })
            .then(r => {
                handleServerResponse(true, "Thanks!", form);
                toggleRecieved();
            })
            .catch(r => {
                handleServerResponse(false, r.response.data.error, form);
            });
    };
    return (
        <form onSubmit={handleOnSubmit}>
            <div className={"grid grid-cols-2 gap-5"}>
                <div className={"flex flex-col"}>
                    <label className={"font-exo2 text-lg"}>
                        Name
                        <span className={"text-red-400"}> *</span>
                    </label>
                    <input
                        className={"border border-gray-500 py-2 px-3 mt-1"}
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name"
                    />
                </div>
                <div className={"flex flex-col"}>
                    <label className={"font-exo2 text-lg"}>
                        Email
                        <span className={"text-red-400"}> *</span>
                    </label>
                    <input
                        className={"border border-gray-500 py-2 px-3 mt-1"}
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                    />
                </div>
            </div>
            <div className={"grid grid-cols-2 gap-5 mt-5"}>
                <div className={"flex flex-col"}>
                    <label className={"font-exo2 text-lg"}>
                        Phone
                        <span className={"text-red-400"}> *</span>
                    </label>
                    <input
                        className={"border border-gray-500 py-2 px-3 mt-1"}
                        type="phone"
                        id="phone"
                        name="phone"
                        placeholder="Phone"
                    />
                </div>
                <div className={"flex flex-col"}>
                    <label className={"font-exo2 text-lg"}>
                        Company
                        <span className={"text-red-400"}> *</span>
                    </label>
                    <input
                        className={"border border-gray-500 py-2 px-3 mt-1"}
                        type="text"
                        id="company"
                        name="company"
                        placeholder="Company"
                    />
                </div>
            </div>
            <div className={"flex flex-col mt-5"}>
                <label className={"font-exo2 text-lg"}>
                    Subject
                    <span className={"text-red-400"}> *</span>
                </label>
                <input
                    className={"border border-gray-500 py-2 px-3 mt-1"}
                    type="text"
                    id="subject"
                    name="subject"
                    placeholder="Subject message"
                />
            </div>
            <div className={"flex flex-col mt-5"}>
                <label className={"font-exo2 text-lg"} >
                    Message
                </label>
                <textarea
                    className={"border border-gray-500 py-2 px-3 mt-1"}
                    id="message"
                    name="message"
                    placeholder="Message"
                    rows={4}
                />
            </div>
            <div className={"flex justify-center mt-5"}>
                <button
                    className={
                        "h-14 w-48 bg-epg-gray text-lg font-semibold text-epg-white font-exo2"
                    }
                    type="submit"
                >
                    <FontAwesomeIcon
                        className={"text-epg-white  text-lg mr-3"}
                        icon={faPaperPlane}
                    />
                    Send
                </button>
            </div>
        </form>
    );
};
export default Contact
